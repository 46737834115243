export const items = [
	{
		title: 'Recolección',
		text: `Para la utilización del Sitio y/o la adquisición de productos o servicios y/o la inscripción a La Whiskería es necesario que el Usuario proporcione a WHISKY BOUTIQUE algunos datos personales. El uso del Sitio por parte del Usuario implica el consentimiento libre, expreso e informado para la recolección y procesamiento de sus datos personales. Si bien no es obligatorio proporcional los datos personales, resulta imprescindible para poder usar el Sitio, adquirir productos y/o servicios e inscribirse a La Whiskería. La negativa a facilitar los datos personales o su inexactitud impide el correcto uso del Sitio, y la posibilidad de adquirir productos y/o servicios y la inscripción a La Whiskería. `,
	},
	{
		title: 'Finalidad',
		text: `Los datos personales del usuario se recaban con la finalidad de adquirir los productos y servicios ofrecidos en el sitio, obtener información acerca de ellos y poder participar de La Whiskería. Los datos personales serán usados para responder a las solicitudes del Usuario, para brindarle información sobre cambios y/o novedades, para requerirle información sobre la experiencia en el uso del Sitio y/o los productos o servicios con el objeto de mejorar el Sitio, para atención al cliente y para publicitar los productos y servicios.  El Usuario podrá recibir comunicaciones de WHISKY BOUTIQUE o de quienes ésta designe incluyendo, pero no limitándose a avisos, consultas, encuestas y/o notificaciones. En caso de optar por salir de nuestra lista de destinatarios de comunicaciones de marketing, podrá ejercer tal opción en cualquier momento, haciendo “click” en el link con dicha opción que encontrará al pie de cada comunicación de marketing que reciba de WHISKY BOUTIQUE. `,
	},
	{
		title: `Almacenamiento`,
		text: `los datos personales serán almacenados por WHISKY BOUTIQUE durante el tiempo requerido para la finalidad para la cual fueron proporcionados. Algunos de los datos proporcionados pueden ser almacenados o procesados por terceros en servidores ubicados fuera de la República Argentina y en jurisdicciones cuyas leyes de protección de la información pueden diferir de la jurisdicción argentina y no garantizar niveles de protección adecuados. En tales supuestos, le aseguramos que las protecciones adecuadas serán acordadas con el proveedor. Usted acepta expresamente la cesión y/o transferencia de sus datos personales a terceros proveedores de servicios de almacenaje y/o procesamiento de datos que garanticen a WHISKY BOUTIQUE el cumplimiento de niveles de protección adecuados a la normativa argentina.`,
	},
	{
		title: `Derechos de acceso, rectificación y supresión`,
		text: `El Usuario es titular de sus datos personales y como tal tiene la facultad de ejercer el derecho de acceso en forma gratuita a intervalos no inferiores a seis meses, salvo que acredite un interés legítimo al efecto, conforme a lo establecido en el artículo 14 inciso 3 de la Ley Nº 25.326 de protección de datos personales. Asimismo, tiene derecho a solicitar la corrección o actualización de sus datos personales y a retirar el consentimiento prestado a WHISKY BOUTIQUE en cualquier momento, pudiendo solicitar su eliminación de los registros en los cuales se encuentren almacenados. Para ejercer los derechos o facultades antes mencionados podrá comunicarse con WHISKY BOUTIQUE a través de contacto@whiskyboutique.com.ar. En todas las solicitudes el titular del dato deberá acreditar su identidad. La Agencia de Acceso a la Información Pública es el órgano de Control de la Ley Nº 25.326 de protección de datos personales y tiene la atribución de atender denuncias y reclamos.`,
	},
	{
		title: 'Registro de bases de datos',
		text: `WHISKY BOUTIQUE cumple con la ley Nº 25.326 y tiene registradas sus bases de datos ante la Agencia de Acceso a la Información Pública.`,
	},
	{
		title: 'Seguridad',
		text: 'WHISKY BOUTIQUE adopta medidas de seguridad técnicas y de organización a fin de proteger los datos personales de la manipulación, pérdida, destrucción o acceso por personas no autorizadas. ',
	},
	{
		title: `Uso de Cookies`,
		text: `WHISKY BOUTIQUE utiliza “cookies” en algunas áreas del Sitio. Las cookies son pequeños archivos que el Sitio instala en el disco duro de su equipo, es muy común su utilización en Internet ya que permiten mejorar el Sitio y su experiencia al utilizarlo. WHISKY BOUTIQUE utiliza cookies para identificar tendencias, recordar y reconocer a los usuarios, y personalizar el Sitio de acuerdo con sus preferencias. El Usuario puede deshabilitar o bloquear cookies en su navegador en cualquier momento, pero hay algunas que son estrictamente necesarias para el correcto funcionamiento del Sitio y si se deshabilitan o bloquean se puede impedir el uso del Sitio.`,
	},
]
