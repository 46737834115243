<script>
import { items } from './utils/privacyItems.js'

export default {
	metaInfo: {
		title: 'Políticas de privacidad',
	},
	pageView: true,
	data() {
		return {
			items,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div class="pb-10">
		<div
			class="darkgreen d-flex align-center justify-center text-center white--text h100 contrast py-16"
			:class="isMobile ? 'h5' : 'h4'"
		>
			POLÍTICAS DE PRIVACIDAD
		</div>
		<Container class="pa-0 px-4 px-lg-0 mb-10" width="100%" max-width="1024px">
			<div class="mt-10 text-center text-md-start" v-for="(item, index) of items" :key="index">
				<p class="mb-2 text-uppercase" :class="isMobile ? 's5' : 's3'">
					{{ item.title }}
				</p>
				<p :class="isMobile ? 't3' : 't1'">
					{{ item.text }}
				</p>
			</div>
		</Container>
	</div>
</template>

<style scoped>
.contrast {
	background: rgba(0, 0, 0, 0.5);
}
</style>
