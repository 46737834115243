export const items = [
	{
		title: 'CONTRATO',
		text: 'Los presentes términos y condiciones (en adelante los “Términos y Condiciones”) regulan la relación contractual entre AGUA DE VIDA) S.A., CUIT 30-71741751-4, ubicada en la calle Cerrito 1070 pis 3 oficina 71 de CABA, Argentina-, (en adelante "WHISKY BOUTIQUE") y el usuario (en adelante el “Usuario”) del sitio  www.whiskyboutique.com.ar (en adelante el “Sitio”). ',
	},
	{
		title: 'ACEPTACIÓN',
		text: 'El uso del Sitio y/o la adquisición de los productos y servicios ofrecidos por WHISKY BOUTIQUE implica la aceptación por parte del Usuario, de los Términos y Condiciones.  Si el Usuario no está de acuerdo con alguna de las disposiciones contenidas en los Términos y Condiciones deberá abstenerse de ingresar al Sitio y de usar y/o adquirir los productos y servicios allí ofrecidos. Si los Usuarios utilizan cualquiera de los servicios ofrecidos por WHISKY BOUTIQUE a través del Sitio, significa que han leído, entendido, acordado y aceptado las normas antes expuestas. ',
	},
	{
		title: 'MODIFICACIONES',
		text: 'WHISKY BOUTIQUE podrá modificar en cualquier momento los Términos y Condiciones. Los cambios serán notificados mediante la publicación de una versión actualizada en el Sitio con expresión de la fecha de la última modificación. Las modificaciones entrarán en vigor a los 30 (treinta) días de su publicación.',
	},
	{
		title: 'MAYORÍA DE EDAD',
		text: ` Sólo podrán acceder, usar, realizar transacciones y/o cualquier manera de vincularse con el Sitio personas mayores de 18 años, conforme lo establecido por el artículo 6 inciso a) de la ley 24.788 (Ley Nacional de Lucha Contra el Alcoholismo), estando prohibida cualquier actividad de menores de edad.
			Tanto el Sitio como los servicios provistos por él se encuentran disponibles para aquellas personas que tengan capacidad legal para contratar bajo las leyes de la República Argentina. No podrán hacer uso del sitio las personas que no cumplan con dicha capacidad, los menores de edad, usuarios y/o quienes hayan sido suspendidos o dados de baja del sitio por haber incumplido los Términos y Condiciones o por haber incurrido, a criterio de WHISKY BOUTIQUE, en conductas o actos dolosos o fraudulentos.
			Al operar con el Sitio, el Usuario deberá brindar información veraz, completa y precisa, y será el único responsable por la información proporcionada.
			`,
	},
	{
		title: 'PRODUCTOS Y SERVICIOS',
		text: 'WHISKY BOUTIQUE ofrece la posibilidad de adquirir bebidas alcohólicas, accesorios vinculados al mundo de las bebidas y servicios de asesoramiento relacionados. Para poder adquirir los productos y servicios ofrecidos el Usuario deberá brindar a WHISKY BOUTIQUE la información personal necesaria para posibilitar la compraventa, pago y entrega. Además, el Usuario tendrá la opción de registrarse en el Sitio para formar parte del club virtual “La Whiskería” y para ello deberá proporcionar información adicional. Toda la información revelada por el Usuario a WHISKY BOUTIQUE será tratada conforme lo establecido en la ley Nº 25.326 y las políticas de privacidad que se especifican más abajo. El código de acceso o usuario y contraseña que serán brindados al Usuario al inscribirse en La Whiskería son de uso exclusivo y no podrán ser cedidos ni revelados a terceros. WHISKY BOUTIQUE puede cambiar los productos y/o servicios ofrecidos en el Sitio en cualquier momento y sin previo aviso. ',
	},
	{
		title: 'PRECIO Y FORMAS DE PAGO',
		text: `Los precios de los productos y servicios, y los medios de pago son los publicados en el Sitio. El Usuario reconoce y acepta que la información sobre los productos y sus precios está sujeta a cambios sin previo aviso. Todos los precios publicados en el sitio se expresan en pesos argentinos y tienen el IVA incluido, salvo que expresamente se indique lo contrario. 
			El pago deberá efectuarse al momento de concretar la compra a través del Sitio y siempre antes de la entrega del producto y podría no concretarse por diferentes motivos como, por ejemplo: falta de stock, no ser aprobada por las entidades que operan los medios de pago y/o por la evaluación del riesgo crediticio del Usuario. El Usuario declara conocer y aceptar que el Sitio podrá solicitarle datos adicionales a fin de poder concertar la operación.
			`,
	},
	{
		title: 'STOCK DE PRODUCTOS Y ENTREGAS',
		text: `Al momento de concretar la operación el producto que el usuario desea adquirir podría no estar disponible debido al dinamismo que posee el Sitio y por lo tanto la compra podría no ser concretada en función del stock disponible. 
			Los gastos de envío son siempre a cuenta del Usuario salvo que se especifique lo contrario. y se calcularán en función del monto de la compra y el lugar de envío.`,
	},
	{
		title: 'PROMOCIONES',
		text: 'Las promociones no son acumulativas ni combinables y sólo tendrán validez para los pedidos efectuados durante su periodo de vigencia.',
	},
	{
		title: 'RESPONSABILIDAD DE WHISKY BOUTIQUE',
		text: 'WHISKY BOUTIQUE no se responsabiliza de los daños y/o perjuicios derivados del acceso, uso o mala utilización del Sitio, su contenido y/o de los Productos ofrecidos. El Usuario declara conocer que el Sitio funciona en conexión y dependencia con sistemas informáticos de terceros por lo que su prestación puede ser afectada por circunstancias de capacidad, disponibilidad o funcionamiento propias de estos sistemas informáticos. Asimismo, el Sitio y su funcionamiento pueden verse inhabilitados, condicionados o afectados por fenómenos climáticos y otras circunstancias imprevisibles o inevitables. Por ello WHISKY BOUTIQUE no se responsabiliza por las interrupciones de cualquier naturaleza y que por cualquier causa impidan el uso y/o acceso al Sitio. Además, la operatividad del Sitio puede ser interrumpida o suspendida por trabajos necesarios para su correcto funcionamiento. El Usuario reconoce y acepta que todas estas circunstancias son ajenas a WHISKY BOUTIQUE y por lo tanto esta queda eximida de toda responsabilidad.',
	},
	{
		title: 'OBLIGACIONES DEL USUARIO',
		text: 'El Usuario tiene estrictamente prohibido distribuir, modificar, duplicar, copiar transmitir, reutilizar, republicar, o usar de forma alguna todo o parte del contenido del Sitio ya sea para fines públicos como comerciales, incluyendo sus textos, imágenes, archivos de audio y video, salvo que exista expreso y específico consentimiento escrito de WHISKY BOUTIQUE. WHISKY BOUTIQUE se reserva el derecho a borrar, suspender o cancelar a cualquier Usuario que no cumpla con los Términos y Condiciones o con las políticas de privacidad o con las normas vigentes. La trasgresión a cualquiera de los Términos y Condiciones, así como a cualquier ley o regulación aplicable, podrá ocasionar perjuicios a WHISKY BOUTIQUE, a sus representantes, directores, empleados o terceros. Por ello, el Usuario acepta indemnizar todos los daños y perjuicios de cualquier naturaleza que se ocasione al afectado y deberá mantener indemne a WHISKY BOUTIQUE respecto de los reclamos iniciados por terceros como consecuencia de tales incumplimientos o transgresiones.',
	},
	{
		title: 'PROPIEDAD INTELECTUAL',
		text: 'El material, la información y las marcas publicadas en el Sitio (en adelante “la Propiedad Intelectual”) son propiedad de WHISKY BOUTIQUE y están protegidos por las normas de propiedad intelectual e industrial nacionales e internacionales. La Propiedad Intelectual se provee al Usuario únicamente para su información y uso personal, no puede ser usada, copiada, reproducida, distribuida, transmitida, exhibida, vendida, licenciada, o explotada de cualquier otra forma sin autorización previa y por escrito de WHISKY BOUTIQUE.',
	},
	{
		title: 'SITIOS E INFORMACION DE TERCEROS',
		text: 'Algunos enlaces pueden hacer que el Usuario abandone el Sitio. WHISKY BOUTIQUE no controla los sitios enlazados de terceros y no es responsable de su contenido. Si el Usuario decide visitar cualquier sitio enlazado será por su propia cuenta y riesgo. Será su responsabilidad tomar todas las medidas necesarias para protegerse contra virus u otros elementos o programas dañosos.',
	},
	{
		title: 'NOTIFICACIONES',
		text: 'Cualquier notificación que deba realizarse a los Usuarios se hará a través del Sitio o al correo electrónico informado por ellos. En caso de que los usuarios deban realizar alguna notificación a WHISKY BOUTIQUE deberán hacerlo por escrito a contacto@whiskyboutique.com.ar.',
	},
	{
		title: 'LEY APLICABLE Y JURISDICCIÓN',
		text: `Este contrato estará regido por las leyes de la República Argentina. 
			La utilización del Sitio y los servicios está expresamente prohibida en toda jurisdicción en donde no puedan ser aplicados los Términos y Condiciones. Las personas que acceden al Sitio desde otra jurisdicción lo hacen por su propia voluntad y asumen la responsabilidad de respetar la legislación local.
			`,
	},
	{
		title: 'CAMBIOS Y DEVOLUCIONES. DERECHO DE REVOCACION',
		text: `Cambios de Productos: el Usuario puede cambiar su compra por cualquier otro producto del Sitio dentro del plazo de diez (10) días de recibido, debiendo abonar las diferencias de precio que pudieran corresponder. En tal caso, el costo de envío estará a cargo del Usuario. 
			Producto defectuoso: si los productos presentaran algún defecto de fabricación, hubieren sufrido roturas o deterioros en tránsito, o fueren despachados equivocadamente, el Usuario deberá informarlo dentro del plazo de diez (10) días para que WHISKY BOUTIQUE proceda a gestionar las acciones correspondientes. El costo de devolución de la compra estará a cargo de WHISKY BOUTIQUE.
			Revocación: en virtud de lo dispuesto por el artículo 1110 del Código Civil y Comercial de la Nación el Usuario tiene el derecho irrenunciable de revocar la aceptación de la compra dentro de los diez (10) días computados a partir de la celebración del contrato o la entrega del bien, lo último que suceda, debiendo restituir el producto adquirido en perfecto estado de uso y conservación, junto con sus etiquetas, embalaje y accesorios originales, también en perfecto estado. Si el plazo vence en día inhábil, se prorroga hasta el primer día hábil siguiente. Una vez recibido el producto en el domicilio de WHISKY BOUTIQUE y examinado, se procederá a la restitución del precio por el mismo medio en que fue abonado.
			Para notificar una solicitud de cambio, devolución o revocación el Usuario debe enviar un mail a contacto@whiskyboutique.com.ar, especificando el Nº de Orden e indicando en el cuerpo del mail qué producto desea cambiar o devolver y el motivo. 
			Los gastos de envío y retiro correrán por cuenta del Usuario y WHISKY BOUTIQUE podrá descontarlos de los fondos a reembolsar.
			Tener en cuenta que, al igual que las entregas, las devoluciones pueden demorar hasta 15 días hábiles.
			`,
	},
]
