<script>
export default {
	metaInfo: {
		title: 'Políticas de Cambio y Devoluciones',
	},
	pageView: true,
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	data() {
		return {
			items: [
				{
					title: 'CAMBIOS Y DEVOLUCIONES. DERECHO DE REVOCACION.',
					content:
						'Cambios de Productos: el Usuario puede cambiar su compra por cualquier otro producto del Sitio dentro del plazo de diez (10) días de recibido, debiendo abonar las diferencias de precio que pudieran corresponder. En tal caso, el costo de envío estará a cargo del Usuario. Producto defectuoso: si los productos presentaran algún defecto de fabricación, hubieren sufrido roturas o deterioros en tránsito, o fueren despachados equivocadamente, el Usuario deberá informarlo dentro del plazo de diez (10) días para que WHISKY BOUTIQUE proceda a gestionar las acciones correspondientes. El costo de devolución de la compra estará a cargo de WHISKY BOUTIQUE.Revocación: en virtud de lo dispuesto por el artículo 1110 del Código Civil y Comercial de la Nación el Usuario tiene el derecho irrenunciable de revocar la aceptación de la compra dentro de los diez (10) días computados a partir de la celebración del contrato o la entrega del bien, lo último que suceda. Si el plazo vence en día inhábil, se prorroga hasta el primer día hábil siguiente. Para notificar una solicitud de cambio, devolución o revocación el Usuario debe enviar un mail a contacto@whiskyboutique.com.ar, especificando el Nº de Orden e indicando en el cuerpo del mail qué producto desea cambiar o devolver y el motivo. Tener en cuenta que, al igual que las entregas, las devoluciones pueden demorar hasta 15 días hábiles.',
				},
			],
		}
	},
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div>
		<div
			class="darkgreen text-center d-flex align-center justify-center white--text h100 contrast text-uppercase py-16"
			:class="isMobile ? 'h5' : 'h4'"
		>
			Políticas de Cambio y Devoluciones
		</div>
		<Container class="pa-0 px-4 px-lg-0 my-15" width="100%" max-width="1024px">
			<div class="mt-10 text-center text-md-start" v-for="(item, index) of items" :key="index">
				<p class="mb-2" :class="isMobile ? 's5' : 's3'">
					{{ item.title }}
				</p>
				<p :class="isMobile ? 't3' : 't1'">
					{{ item.content }}
				</p>
			</div>
		</Container>
	</div>
</template>
<style scoped>
.contrast {
	background: rgba(0, 0, 0, 0.5);
}
</style>
